const star = '/_nuxt/star.svg';
const starEmpty = '/_nuxt/star--empty.svg';
const starHalf = '/_nuxt/star--half.svg';

export const getStar = (rating: number, index: number) => {
	if (rating >= index + 1) {
		return star;
	} else if (Math.floor(rating) === index) {
		const diff = rating - Math.floor(rating);

		if (diff > 0.5) {
			return diff - 0.5 >= 0.25 ? star : starHalf;
		} else {
			return diff >= 0.25 ? starHalf : starEmpty;
		}
	} else {
		return starEmpty;
	}
};
